import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import PropTypes from 'prop-types'
import React , {useState, useEffect} from "react"
import DatalakeService from "src/services/datalake-service"


const TabelaIndicador = ({ tipoBalanco,raiz_cnpj }) => {
    const [data, setData] = useState([])
    const calcService = new DatalakeService()
    console.log(tipoBalanco,raiz_cnpj)


    useEffect(() => {
        loadData()
    },[tipoBalanco,raiz_cnpj])

    const loadData = () => {
        calcService.getIndicesBalanco(raiz_cnpj, tipoBalanco).then((response) => {
            const { body } = response
            const { data } = body
            
            const list = data.map((obj, index) => ({
                id: index,
                DataReferencia: obj.DataReferencia.replace(/-/g, '/'),
                ROA: String(obj.ROA).replace(/\./g, ',')
            }))
            console.log(list)
            setData(list)
        })
        .catch((error) => {
            
            console.error("Erro ao buscar dados:", error)
            setData([])
        })
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>%</TableCell>
                        {data.map((item, index) => (
                            <TableCell key={index} align="right">{item.DataReferencia}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>ROA</TableCell>
                        {data.map((item, index) => (
                            <TableCell key={index} align="right">{item.ROA}</TableCell>
                        ))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

TabelaIndicador.propTypes = {
    raiz_cnpj: PropTypes.string,
    tipoBalanco: PropTypes.string,
}

export default TabelaIndicador
