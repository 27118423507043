import { Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary, Select, MenuItem} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TabelaIndicador from "./indicadores-financeiros"
import TabelaBalanco from "./balancos-patrimonial"
//import DatalakeService from "src/services/datalake-service"


const IndicadorBalanco = ({  raiz_cnpj,listaEncontrados }) => {
    const [tipoBalanco, setTipoBalanco] = useState("")
    const [expanded, setExpanded] = useState(false)
    //const calcService = new DatalakeService()
    
    const toggleAccordion = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        if (!expanded) {
            toggleAccordion()
        }
        if (listaEncontrados.length > 0) {
            setTipoBalanco(listaEncontrados[0]) // Define o primeiro item como padrão
        }
    },[listaEncontrados])

    const formatarCNPJ = (cnpj) => {
        const cnpjString = String(cnpj || "")
        const cnpjLimpo = cnpjString.replace(/\D/g, "")
    
        if (cnpjLimpo.length === 8) {
            return cnpjLimpo.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2.$3")
        }
    
        return cnpjLimpo
    }
    const cnpjString = formatarCNPJ(raiz_cnpj)

    console.log("cnpjString:",cnpjString.type)


    return <>
        <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Select value={tipoBalanco} onChange={(e) => setTipoBalanco(e.target.value)}>
                        {listaEncontrados.map((tipo) => (
                            <MenuItem key={tipo} value={tipo}>
                                {tipo}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="balanco-patrimonial-content"
                            id="balanco-patrimonial-content"

                        >
                            <Typography>Balanço Patrimonial</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TabelaBalanco tipoBalanco={tipoBalanco} raiz_cnpj={cnpjString}  />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={expanded} onChange={(e, isExpanded) => setExpanded(isExpanded)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="indicadores-financeiros-content"
                            id="indicadores-financeiros-content"
                        >
                            <Typography>Indicadores Financeiros</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TabelaIndicador tipoBalanco={tipoBalanco} raiz_cnpj={cnpjString}  />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
        </Grid>
    </>
}
IndicadorBalanco.propTypes = {
    admin: PropTypes.bool,
    raiz_cnpj: PropTypes.string,
    value: PropTypes.string,
    loading: PropTypes.func,
    listaEncontrados: PropTypes.array,
}

export default IndicadorBalanco