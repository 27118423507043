import { ServiceRequest } from "src/utils/service-request"
import { DATALAKE_EMPRESAS_PATH, DATALAKE_GRUPO_ECONOMICO_PATH, CALC_REPORT_ERROR, DATALAKE_SETOR_LIST_PATH, DATALAKE_ULTIMO_PD_PATH, DATALAKE_CVM_FATOS_RELEVANTES_PATH,DATALAKE_EMPRESAS_LIST_PATH,DATALAKE_INFO_ADICIONAIS_PATH, DATALAKE_CONTRAPARTE_PATH,CALC_SETORIAL_PATH, DATALAKE_EMPRESAS_FILE_PATH, DATALAKE_INDICES_BALANCOS_PATH } from "src/utils/api-routes"
import { TokenService } from "./token-service"
import {getFormattedNumberValue} from "src/utils/format-decimal"

class DatalakeService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
        this.tokenService = new TokenService()

    }

    async paginate(endpoint) {
        return await this.serviceRequest.getEndpoint(endpoint)
    }

    async loadEndpoint(endpoint, filter = null) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000&${filter}`)
    }

    async loadPaginationEndpoint(endpoint, filter = null, limit = 25, offset = 0) {
        return await this.serviceRequest.get(`${endpoint}?limit=${limit}&offset=${offset}&${filter}`)
    }

    async createTableEndpoint(endpoint, body) {
        console.log(endpoint, body)
        return await this.serviceRequest.post(`${endpoint}`, body)
    }

    async updateTableEndpoint(endpoint, id, body) {
        return await this.serviceRequest.patch(`${endpoint}/${id}`, body)
    }

    async deleteTableEndpoint(endpoint, id) {
        return await this.serviceRequest.delete(`${endpoint}/${id}`)
    }

    async loademissoes( body) {
        return await this.serviceRequest.get(`${DATALAKE_EMPRESAS_PATH}?CPFCNPJ=${body}`)
    }

    async loadgrupoecon( body) {
        return await this.serviceRequest.get(`${DATALAKE_GRUPO_ECONOMICO_PATH}?raiz_cnpj=${body}`)
    }

    async loadsetor( body) {
        return await this.serviceRequest.get(`${DATALAKE_SETOR_LIST_PATH}?CPFCNPJ=${body}`)
    }
    
    async loadFatoRelevante( body) {
        return await this.serviceRequest.get(`${DATALAKE_CVM_FATOS_RELEVANTES_PATH}?CodigoCVM=${body}`)
    }

    async loadList() {
        return await this.serviceRequest.get(`${DATALAKE_EMPRESAS_LIST_PATH}`)
    }

    async loadPD(body) {
        return await this.serviceRequest.get(`${DATALAKE_ULTIMO_PD_PATH}?raiz_cnpj=${body}`)
    }

    async loadInfo( body) {
        return await this.serviceRequest.get(`${DATALAKE_INFO_ADICIONAIS_PATH}?CPFCNPJ=${body}`)
    }

    async verificacontraparte(body) {
        return await this.serviceRequest.get(`${DATALAKE_CONTRAPARTE_PATH}?CPFCNPJ=${body}`)
    }

    async recuperaDadosContraparte(input, value) {
        return this.serviceRequest.get(`${DATALAKE_CONTRAPARTE_PATH}?${input}=${value}`)
    }

    async recuperaDadosSetor(input, value) {
        return this.serviceRequest.get(`${CALC_SETORIAL_PATH}?${input}=${value}`)
    }

    async getIndicesBalanco(cnpj, grupo) {
        return this.serviceRequest.get(`${DATALAKE_INDICES_BALANCOS_PATH}?raiz_cnpj=${cnpj}&grupo=${grupo}`)
    }

    async postReportError( message,valor) {
        const { user } = this.tokenService.decodeToken()
        const { username, Cliente, NomeCompleto } = user
        const { Nome } = Cliente

        const body = {
            'title': "Reporte de Erro em Empresas Datalake",
            'data': {
                'client': Nome,
                'name': NomeCompleto,
                'username': username,
                'message': message,
                'empresa': valor && valor.trim() ? valor : "empresa não selecionada",
            }
        }

        return this.serviceRequest.post(CALC_REPORT_ERROR, body)
    }

    sanitizeValue(value, defaultValue = null) {
        return value?.trim() || defaultValue
    }

    sanitizeCPFCNPJ(value, defaultValue = null) {
        if (!value) return defaultValue
    
        const trimmedValue = value.trim()
        const onlyNumbers = trimmedValue.replace(/\D/g, '')
    
        if (onlyNumbers.length !== 14) return defaultValue
    
        return `${onlyNumbers.substring(0, 8)}/${onlyNumbers.substring(8, 12)}-${onlyNumbers.substring(12, 14)}`
    }

    tratPeriodicidade(value) {
        const mapping = {
            0: "Indeterminado",
            1: "Anual",
            2: "Semestral",
            3: "Trimestral",
            6: "Bimestral"
        }
        
        if (typeof value === "number") {
            return mapping[value] || "Entrada inválida"
        } else if (typeof value === "string") {
            const invertedMapping = Object.fromEntries(
                Object.entries(mapping).map(([key, val]) => [val, Number(key)])
            )
            return invertedMapping[value] !== undefined ? invertedMapping[value] : "Entrada inválida"
        }
        return ""
    }
    
    async registerContra(value = {}) {
        console.log(await this.tratPeriodicidade(1))
       const body = {
            CPFCNPJ: this.sanitizeCPFCNPJ(value.CPFCNPJ),
            RaizCNPJ: this.sanitizeValue(value.RaizCNPJ),
            RazaoSocial: this.sanitizeValue(value.RazaoSocial),
            NomeFantasia: this.sanitizeValue(value.NomeFantasia),
            MesFechamento: getFormattedNumberValue(value.MesFechamento) || 12,
            Observacao: this.sanitizeValue(value.Observacao),
            Atividade: this.sanitizeValue(value.Atividade),
            Site_RI: this.sanitizeValue(value.Site_RI),
            GrupoAuditoria: getFormattedNumberValue(value.GrupoAuditoria),
            GrupoCustodiante: getFormattedNumberValue(value.GrupoCustodiante),
            GrupoCoordenadorLider: getFormattedNumberValue(value.GrupoCoordenadorLider),
            GrupoFiduciario: getFormattedNumberValue(value.GrupoFiduciario),
            GrupoIFRecebedora: getFormattedNumberValue(value.GrupoIFRecebedora),
            GrupoSecuritizador: getFormattedNumberValue(value.GrupoSecuritizador),
            Setor: this.sanitizeValue(value.Setor) ,
            SubSetor: this.sanitizeValue(value.SubSetor) ,
            GrupoEconomico_Nome: this.sanitizeValue(value.GrupoEconomico_Nome) ,
            GrupoEconomico_RaizCNPJ: this.sanitizeValue(value.GrupoEconomico_RaizCNPJ) ,
            PeriodicidadeBalanco: this.tratPeriodicidade(value.PeriodicidadeBalanco) ,
        }
        console.log(body)
        return this.serviceRequest.post(DATALAKE_CONTRAPARTE_PATH, body)
    }

    async sendFile(file,tipo_arquivo) {
        try {
            const formData = new FormData()

            formData.append('arquivo', file)
            formData.append('tipo_extensao', tipo_arquivo)
            return this.serviceRequest.postFormData(DATALAKE_EMPRESAS_FILE_PATH, formData)
        } catch (error) {
            console.error("Erro na leitura do arquivo Excel:", error)
        }
    }
}

export default DatalakeService