import { Box, Button, Chip, Grid, Paper, TextField, Tooltip, Typography, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import React, { useEffect, useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import { RoutineService } from "src/services/routine-service"
import { DATE_FORMAT, DATE_TIME_FORMAT_SHOW,DATE_FORMAT_ISO8601 } from "src/utils/constants"
import DatePickerSelect from "src/components/date/date-picker-select"
import AlertMessage from "src/components/alert-message"
import SelectValue from "src/components/select-value"
import { routineDataWorkflowList, routineEndpointWorkflowList } from "./routines-data-workflow"
import dayjs from "dayjs"
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import SkeletonTable from "src/components/skeleton-table"
import { DataGrid } from "@mui/x-data-grid"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import { formatDecimalCase } from "src/utils/format-decimal"
import ProgressBar from "src/components/progress-bar"
import { useDropzone } from "react-dropzone"
import { formatTimestamp } from "src/utils/format-date"



const RoutinesWorkflowPage = () => {
    const [type, setType] = useState("")
    const [loading, setLoading] = useState(false)
    const [qtdeDias, setQtdeDias] = useState(90)
    const [dateSelected, setDateSelected] = useState(dayjs().format(DATE_FORMAT))
    const [alertMessageState, setAlertMessageState] = React.useState({
      open: false,
      message: "",
      type: "",
    })
    const [loadTable, setLoadTable] = useState(false)
    const [routineSelected, setRoutineSelected] = useState(null)
    const [routineWorkList, setRoutineWorkflowList] = useState([])
    const dataPadraoFormatada = dayjs().format(DATE_FORMAT_ISO8601)
    const [dateSelectedFinal1, setDateSelectedFinal1] = useState(dayjs().format('YYYY-MM-DD')) //constante nova pra envios de data formato YYYY-MM-DD
    const [dateSelectedDateFinal, setdateSelectedDateFinal] =
      useState(dataPadraoFormatada)
    const [selectedFile, setSelectedFile] = useState(null)
    const [cetip, setCetip] = useState("")
    const [name_tipo, setNameTipo] = useState(null)
  
    const routineService = new RoutineService()
    const options = routineService.getAPPList()
  
    const onChangeDatePickerDateTimeFinal = (value) => {
      const formattedDate = value.format(DATE_FORMAT)
      setdateSelectedDateFinal(formattedDate)
    }

    const onChangeDatePickerDateFinal1 = (value) => {
      setDateSelectedFinal1(value.format('YYYY-MM-DD')) // Const com a data formatada YYYY-MM-DD
    }
  
    const onDrop = (acceptedFiles) => {
      const file = acceptedFiles[0]
      setSelectedFile(file)
      setType(file.name.split(".")[0]) 
    }
  
    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })
  
    const getFileSize = (size) => {
      const mbSize = size / (1024 * 1024)
      return parseFloat(mbSize).toFixed(2)
    }
  
    useEffect(() => {
      loadLastRoutines()
    }, [])
  
    const extractMetadata = (obj) => {
      const { metadata } = obj
      try {
        const valores = metadata.split(",")
        let data_referencia = valores.find((elemento) =>
          elemento.includes("data_referencia")
        )
        let qtde_dias = valores.find((elemento) => elemento.includes("qtde_dias"))
  
        data_referencia !== undefined
          ? (data_referencia = data_referencia.slice(-11).slice(0, -1))
          : null
        qtde_dias !== undefined
          ? (qtde_dias = qtde_dias.slice(-3).slice(0, -1))
          : 0
  
        return { data_referencia: data_referencia, qtde_dias: qtde_dias }
      } catch (err) {
        return null
      }
    }
  
    const loadLastRoutines = () => {
      setLoadTable(true)
      routineService
        .getRoutinesWorkflowInfo()
        .then((response) => {
          const { body } = response
          const { results } = body
          const rows = results.map((obj, index) => {
            const metadata = extractMetadata(obj)
  
            return {
              ...obj,
              id: index,
              data_referencia: metadata === null ? null : metadata.data_referencia,
              qtde_dias: metadata === null ? 90 : metadata.qtde_dias,
            }
          })
  
          setRoutineWorkflowList(rows)
          setLoadTable(false)
        })
        .catch((err) => {
          console.log(err)
          setLoadTable(false)
        })
    }
  
    const inicialRotina = () => {
      if (routineSelected === null || dateSelected === null) {
        openAlert("Escolha uma rotina e uma data válida", "warning")
        return
      }
  
      let body = { data_referencia: dateSelected }
  
      const endpointSelected = routineEndpointWorkflowList.find(
        (item) => item.value === routineSelected
      )
  
      if (routineSelected === "VolatilidadeCurvasWorkflow") {
        body.qtde_dias = qtdeDias === null ? 90 : qtdeDias
      }
  
      setLoading(true)
  
      if (routineSelected === "PrecosAndados") {
        if (!selectedFile) {
          openAlert("Selecione um arquivo", "warning")
          setLoading(false)
          return
        }
  
        routineService
          .sendFile(dateSelected, type, selectedFile)
          .then(() => {
            setLoading(false)
            openAlert("Processo realizado com sucesso!", "success")
          })
          .catch((err) => {
            const { text } = err.response
            setLoading(false)
            openAlert(`Erro no processo! - ${text}`, "error")
          })
      } else if (routineSelected === "LiquidezWorkflow") {
        routineService
          .startarLiquidezWorkflow(dateSelected, dateSelectedDateFinal)
          .then(() => {
            setLoading(false)
            openAlert("Processo iniciado", "success")
          })
          .catch(() => {
            setLoading(false)
            openAlert("Erro no processo!", "error")
          })
      } else if (routineSelected === "ClientePrecosLogWorkflow") {
        routineService
          .startarClientePrecosLogWorkflow(dateSelected)
          .then(() => {
            setLoading(false)
            openAlert("Processo iniciado", "success")
          })
          .catch(() => {
            setLoading(false)
            openAlert("Erro no processo!", "error")
          })
      } else if (routineSelected === "CurvasWorkflow") {
        routineService
          .startarCurvasWorkflow(dateSelected)
          .then(() => {
            setLoading(false)
            openAlert("Processo iniciado", "success")
          })
          .catch(() => {
            setLoading(false)
            openAlert("Erro no processo!", "error")
          })
        } else if (routineSelected === "PrecosEventosFinanceirosWorkflow") {
          routineService
            .startarPrecosEventosFinanceirosWorkflow(dateSelected)
            .then(() => {
              setLoading(false)
              openAlert("Processo iniciado", "success")
            })
            .catch((err) => {
              setLoading(false)
              openAlert(`Erro no processo! - ${err.message}`, "error")
            })
        } else if (routineSelected === "PrecosProcessadosWorkflow") {
          routineService
            .startarPrecosProcessadosWorkflow(dateSelected, dateSelectedFinal1)
            .then(() => {
              setLoading(false)
              openAlert("Processo iniciado", "success")
            })
            .catch(() => {
              setLoading(false)
              openAlert("Erro no processo!", "error")
            })
          } else if (routineSelected === "SpreadsBrutosTratadosWorkflow") {
            routineService
              .startarSpreadsBrutosTratadosWorkflow(dateSelected)
              .then(() => {
                setLoading(false)
                openAlert("Processo iniciado", "success")
              })
              .catch((err) => {
                setLoading(false)
                openAlert(`Erro no processo! - ${err.message}`, "error")
              })
      } else if (routineSelected === "SpreadEmissaoWorkflow") {
        routineService
          .startarSpreadEmissaoWorkflow(dateSelected, dateSelectedFinal1)
          .then(() => {
            setLoading(false)
            openAlert("Processo iniciado", "success")
          })
          .catch(() => {
            setLoading(false)
            openAlert("Erro no processo!", "error")
          })
        } else if (routineSelected === "SpreadNegociadoWorkflow") {
          routineService
            .startarSpreadNegociadoWorkflow(dateSelected, dateSelectedFinal1)
            .then(() => {
              setLoading(false)
              openAlert("Processo iniciado", "success")
            })
            .catch(() => {
              setLoading(false)
              openAlert("Erro no processo!", "error")
            })
      } else if (routineSelected === "CalculationCetip") {
        routineService
          .startarCalculationCetip(dateSelected, cetip, name_tipo)
          .then(() => {
            setLoading(false)
            openAlert("Cálculo CETIP iniciado", "success")
          })
          .catch((err) => {
            setLoading(false)
            openAlert(`Erro no processo! - ${err.message}`, "error")
          })
      } else {
        routineService
          .startRoutine(endpointSelected.endpoint, body)
          .then(() => {
            setLoading(false)
            openAlert("Processo realizado com sucesso!", "success")
          })
          .catch((err) => {
            const { text } = err.response
            setLoading(false)
            openAlert(`Erro no processo! - ${text}`, "error")
          })
      }
    }
  
    const rebuildIndex = () => {
      routineService
        .startRebuild()
        .then(() => {
          setLoading(false)
          openAlert("Processo realizado com sucesso!", "success")
        })
        .catch((err) => {
          const { text } = err.response
          setLoading(false)
          openAlert(`Erro no processo! - ${text}`, "error")
        })
    }
  
    const alterarValor = (value) => {
      setRoutineSelected(value)
    }
  
    const onChangeDatePickerDate = (value) => {
      setDateSelected(value.format(DATE_FORMAT))
    }
  
    const closeAlert = () => {
      setAlertMessageState({ ...alertMessageState, open: false })
    }
  
    const alterarValorCalculo = (event) => {
      setNameTipo(event.target.value)
    }
  
    const handleCetipChange = (event) => {
      setCetip(event.target.value)
    }
  
    const openAlert = (message, type) => {
      setAlertMessageState({
        ...alertMessageState,
        open: true,
        message: message,
        type: type,
      })
    }
  
    const verifyTableStatus = (params) => {
      const { value, row } = params
      const { text, color } = routineService.verifyTableStatusWorkflow(value)
      const { total_registros, total_registros_processados } = row
  
      return (
        <Tooltip
          title={`processados ${total_registros_processados} de ${total_registros}`}
        >
          <Chip label={text} variant="contained" color={color} />
        </Tooltip>
      )
    }
  
    const progressBar = (params) => {
      const { row, value } = params
      const { total_registros } = row
      const valueBar = (value / total_registros) * 100
      let formatedValue = formatDecimalCase(valueBar)
  
      if (value < 0.1) {
        formatedValue = 0
      }
      return <ProgressBar value={Number(formatedValue)} />
    }
  
    const formatDateUtc = (params, format) => {
      const { value } = params
  
      if (params.value === null) return "N/A"
  
      return dayjs(value).utc().format(format)
    }
  
    const columns = [
      {
        field: "rotina",
        headerName: "Rotina",
        minWidth: 250,
      },
      {
        field: "qtde_dias",
        headerName: "Quantidade de Dias",
        minWidth: 150,
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 200,
        renderCell: verifyTableStatus,
      },
      {
        field: "total_registros",
        headerName: "Total de Registros",
        flex: 1,
        minWidth: 150,
      },
      {
        field: "total_registros_processados",
        headerName: "Progresso",
        minWidth: 150,
        renderCell: (params) => progressBar(params),
      },
      {
        field: "nome_usuario",
        headerName: "Usuário",
        minWidth: 150,
      },
      {
        field: "criado_em",
        headerName: "Data de Criação",
        flex: true,
        minWidth: 200,
        type: "date",
        valueFormatter: (params) => formatDateUtc(params, DATE_TIME_FORMAT_SHOW),
      },
      {
        field: "atualizado_em",
        headerName: "Data de Atualização",
        flex: true,
        minWidth: 200,
        type: "date",
        valueFormatter: (params) => formatDateUtc(params, DATE_TIME_FORMAT_SHOW),
      },
    ]
  
    return (
      <LayoutPage title="Rotinas" loading={loading}>
        <div>
          <AlertMessage
            open={alertMessageState.open}
            message={alertMessageState.message}
            type={alertMessageState.type}
            close={closeAlert}
          />
          <Box sx={{ mx: 2, width: "auto" }}>
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
              <Grid item sm={12} md={6} xl={4} xs={12}>
                <Typography variant={"h5"}>Processo manual das rotinas!</Typography>
              </Grid>
            </Grid>
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
              <Grid item md={6} sm={12} xl={4}>
                <SelectValue
                  label={"Selecione uma rotina"}
                  value={routineSelected}
                  id="select-app"
                  changeValue={alterarValor}
                  options={routineDataWorkflowList}
                />
              </Grid>
            </Grid>
            <br />
            {}
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={1}>
              <Grid item sm={12} md={6} xl={6}>
                <DatePickerSelect
                  label="Data de Exibição"
                  onChange={onChangeDatePickerDate}
                  value={dateSelected}
                />
              </Grid>
            
              {routineSelected === "PrecosProcessadosWorkflow" && (
                <Grid item sm={12} md={6} xl={4} xs={12}>
                  <DatePickerSelect
                    label="Data de Exibição Final"
                    onChange={onChangeDatePickerDateFinal1}
                    value={dateSelectedFinal1}
                  />
                </Grid>
              )}
              {routineSelected === "SpreadEmissaoWorkflow" && (
                <Grid item sm={12} md={6} xl={4} xs={12}>
                  <DatePickerSelect
                    label="Data de Exibição Final"
                    onChange={onChangeDatePickerDateFinal1}
                    value={dateSelectedFinal1}
                  />
                </Grid>
              )}
              {routineSelected === "SpreadNegociadoWorkflow" && (
                <Grid item sm={12} md={6} xl={4} xs={12}>
                  <DatePickerSelect
                    label="Data de Exibição Final"
                    onChange={onChangeDatePickerDateFinal1}
                    value={dateSelectedFinal1}
                  />
                </Grid>
              )}
              {routineSelected === "LiquidezWorkflow" && (
                <Grid item sm={12} md={6} xl={4} xs={12}>
                  <DatePickerSelect
                    label="Data de Exibição Final"
                    onChange={onChangeDatePickerDateTimeFinal}
                    value={dateSelectedDateFinal}
                  />
                </Grid>
              )}
            </Grid>
            <br />
            {routineSelected === "VolatilidadeCurvasWorkflow" && (
              <div>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={1}>
                  <Grid item xs={12} md={6} xl={4}>
                    <TextField
                      type="number"
                      id="qtdeDias"
                      name="qtdeDias"
                      fullWidth
                      value={qtdeDias}
                      variant="standard"
                      label={"Digite a quantidade de dias"}
                      onChange={(e) => setQtdeDias(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <br />
              </div>
            )}
  
            {routineSelected === "PrecosAndados" && (
              <div>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                  <Grid item sm={12} md={6} xl={4} xs={12}>
                    <SelectValue
                      label={"Tipo"}
                      value={type}
                      id="select-type"
                      changeValue={setType}
                      options={options}
                    />
                  </Grid>
                </Grid>
                <br />
  
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                  <Grid item xs={12} md={6} sm={12} xl={4}>
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Grid item>
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <input {...getInputProps()} />
                          <Button variant="outlined" component="span">
                            Arraste e solte o arquivo aqui ou clique para selecionar
                          </Button>
                          <p>
                            {selectedFile ? selectedFile.name : "Nenhum arquivo selecionado"}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
  
                  {selectedFile && (
                    <Grid item xs={12} md={4} xl={3}>
                      <Typography variant="subtitle" gutterBottom>
                        Nome: <b>{selectedFile.name}</b>
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle" gutterBottom>
                        Data de Modificação: <b>{formatTimestamp(selectedFile.lastModified)}</b>
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle" gutterBottom>
                        Tamanho do Arquivo (MB): <b>{getFileSize(selectedFile.size)}</b>
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle" gutterBottom>
                        Tipo: <b>{selectedFile.type}</b>
                      </Typography>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
                <br />
              </div>
            )}
  
            {routineSelected === "CalculationCetip" && (
              <div>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                  <Grid item xs={12} md={6} sm={12} xl={4}>
                    <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Calculo</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={name_tipo}
                          label="Rotinas"
                          onChange={alterarValorCalculo}
                        >
                          <MenuItem value="">Escolha um Calculo</MenuItem>
                          <MenuItem value="PUPAR">PUPAR</MenuItem>
                          <MenuItem value="PUMTM">PUMTM</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12} md={6} xl={3}>
                  <FormControl>
                    <InputLabel htmlFor="cetip"></InputLabel>
                    <TextField
                      id="cetip"
                      value={cetip}
                      onChange={handleCetipChange}
                      variant="outlined"
                      placeholder="Digite o Cetip"
                    />
                  </FormControl>
                </Grid>
              </div>
            )}
  
            <br />
            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
              <Grid item sm={12} md={6} xl={4} xs={12}>
                <Button variant="contained" onClick={inicialRotina}>
                  Iniciar Rotina
                </Button>
              </Grid>
            </Grid>
            <br />
  
            {routineSelected === "VolatilidadeCurvasWorkflow" && (
              <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                  <Button variant="contained" onClick={rebuildIndex}>
                    Rebuild index
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
  
          <br />
          <br />
          <Grid container direction="row" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant={"h5"}>Últimas Rotinas</Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => loadLastRoutines()}>
                <RefreshRoundedIcon />
              </Button>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" justifyContent="left" spacing={2}>
            <Grid item xl={12} md={12} xs={12}>
              {loadTable ? (
                <SkeletonTable />
              ) : (
                <Paper>
                  <DataGrid
                    autoHeight
                    getRowHeight={() => 45}
                    rows={routineWorkList}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableRowSelectionOnClick
                    sortingOrder={["asc", "desc"]}
                    localeText={localeBRDataGridText}
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </div>
      </LayoutPage>
    )
  }
  
  export default RoutinesWorkflowPage